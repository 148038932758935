import React from "react";
import SearchResultPage from "../../../templates/search-results-template";

const SearchResultCommLettings = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/commercial-property/to-rent/" 
                pcategorytype="commercial" 
                ptype="lettings" 
                ptypetag="to-rent" 
                pstatustype="To Let" 
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                tag={"commercial"}
            />
        </React.Fragment>
    );
};

export default SearchResultCommLettings;
